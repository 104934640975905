:root {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body,
input,
input[type='text'],
input[type='password'],
input[type='submit'] textarea,
button {
    font-family: 'Montserrat', 'Arial', 'Roboto', 'Franklin Gothic Medium', sans-serif;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
}

#launcher {
    display: none;
}

.dir #launcher,
.DIR #launcher {
    display: block;
    margin: 10px 16px 64px !important;
}

@media screen and (min-width: 800px) {
    .dir #launcher,
    .DIR #launcher {
        margin: 10px 16px !important;
    }
}

.quill.quill.quill {
    flex: 0 0 100%;
}

.ql-container.ql-container.ql-container {
    border: 0;
    font-family: inherit;
    font-size: inherit;
    max-height: 6em;
    border-radius: 66px;
}

@media screen and (min-width: 800px) {
    .ql-container.ql-container.ql-container {
        height: 6.5em;
        max-height: 6.5em;
    }
}

.ql-toolbar.ql-toolbar.ql-toolbar {
    display: none;
    border: 0;
}

@media screen and (min-width: 800px) {
    .ql-toolbar.ql-toolbar.ql-toolbar {
        display: block;
        display: none;
    }
}

.ql-editor.ql-editor.ql-editor {
    border: 0;
    padding: 0.5em;
}

.ql-formats.ql-formats.ql-formats.ql-formats {
    margin-right: 0;
}

@media screen and (min-width: 1100px) {
    .ql-formats.ql-formats.ql-formats.ql-formats {
        margin-right: 1em;
    }
}

.ql-editor.ql-blank.ql-blank::before {
    font-style: normal;
}


