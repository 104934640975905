.is-ie {
  padding: 64px 32px 32px;
  font-size: 1.25em;
}

.c-browser-link {
  color: inherit;
  text-decoration: none;
  line-height: 2em;
  display: block;
  margin-top: 1em;
}

.c-browser-link__logo {
  display: inline-block;
  width: 2em;
  height: auto;
  vertical-align: middle;
}
